<template>
  <section>
    <b-card>
      <b-breadcrumb>
        <div class="d-flex align-items-center">
          <span class="text-primary">Report </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              Dettaglio auto vendute</b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
      </b-breadcrumb>
    </b-card>

    <b-row>
      <b-col md="12">
        <b-card> 

          <div class="d-flex">
          <v-select />

          <v-select class="mx-2"/>

          <b-button variant="primary">Mostra</b-button>
          </div>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card>
          <span class="text-primary-light h3"
            >Totale mensile delle auto vendute:
          </span>
          <span class="h3">xx auto vendute</span>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-card>
          <h2>Le marche più vendute</h2>

          <chartjs-component-doughnut-chart :data="doughnutChart.data" :options="doughnutChart.options" />
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card>
          <h2>I modelli più venduti</h2>

          <chartjs-component-doughnut-chart :data="doughnutChart.data" :options="doughnutChart.options" />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAvatar,
  BButton,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
} from "bootstrap-vue";

import vSelect from "vue-select";

import ChartjsComponentDoughnutChart from "@/components/charts/ChartjsComponentDoughnutChart.vue";

export default {
  components: {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    vSelect,
    ChartjsComponentDoughnutChart,
  },

  data: () => ({

    doughnutChart: {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      cutoutPercentage: 0,
      legend: { display: true },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
      },
    },
    data: {
      datasets: [
        {
          labels: ['Marca auto 1', 'Marca auto 2', 'Marca auto 3'],
          data: [890, 590, 290],
          backgroundColor: ['#377DFF', '#5E5873', '#2333B6'],
          borderWidth: 5,
          pointStyle: 'rectRounded',
        },
      ],
    },
  },

  }),
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
